import { graphql } from "gatsby";
import React from "react";
import Articles from "component/Articles/Articles";
import Layout from "component/Layout/Layout";
import Seo from "component/seo";
import SidebarLeft from "component/Sidebar/SidebarLeft";
import SidebarRight from "component/Sidebar/SidebarRight";
import { useSiteUrl } from "utils";

export default function ArticlesPage(props) {
  const { posts, totalCount } = props.data.allContentfulPosts;
  const {
    pageContext: { currentPage, numPages },
    location,
  } = props;
  const canonicalUrl = `${useSiteUrl()}${location.pathname}`;

  return (
    <Layout>
      <Seo title="Articles" titleSample={true} canonicalUrl={canonicalUrl} />
      <SidebarLeft />
      <Articles
        title="Articles"
        posts={posts}
        currentPage={currentPage}
        numPages={numPages}
        totalCount={totalCount}
      />
      <SidebarRight />
    </Layout>
  );
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allContentfulPosts(
      skip: $skip
      limit: $limit
      sort: { fields: date, order: DESC }
    ) {
      posts: nodes {
        title
        url
        video
        id
        sourceCode
        description
        date(formatString: "MMMM DD, YYYY")
        image {
          gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1, width: 200)
        }
        tags {
          id
          name
          url
        }
      }
      totalCount
    }
  }
`;

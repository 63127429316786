import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";

const paginationCSS = {
  self: css`
    display: flex;
    align-items: center;
    margin: 4rem 0;
    position: relative;
    justify-content: space-between;
    z-index: 10;
    font-family: var(--font-family-three);
    font-size: 1.9rem;
    @media only screen and (max-width: 576px) {
      margin: 2rem 0;
    }
  `,

  button: css`
    &[disabled] {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.3);
    }

    cursor: pointer;
    backface-visibility: hidden;
    color: var(--color-dark);
    position: relative;
    z-index: 15;
    text-decoration: underline;
    text-decoration-thickness: 2px;

    &:focus {
      outline: 2px dashed var(--color-secondary);
      outline-offset: 5px;
    }
  `,
};

export default function Pagination({ currentPage, numPages }) {
  const nextPage = currentPage + 1;
  const prevPage = currentPage - 1;
  return (
    <div css={paginationCSS.self}>
      {prevPage === 0 ? (
        <span css={paginationCSS.button} disabled>
          Newer posts
        </span>
      ) : (
        <Link
          css={paginationCSS.button}
          to={prevPage === 1 ? `/articles/` : `/articles/${prevPage}`}
        >
          Newer posts
        </Link>
      )}
      {/* <span className="info-page">
        {currentPage} of {numPages}
      </span> */}
      {nextPage > numPages ? (
        <span className="">That's all</span>
      ) : (
        <Link css={paginationCSS.button} to={`/articles/${nextPage}`}>
          Older posts
        </Link>
      )}
    </div>
  );
}

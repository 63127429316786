import React from "react";
import InfoHeader from "../InfoHeader/InfoHeader";
import Pagination from "../Pagination/Pagination";
import Post from "../Post/Post";
import styled from "@emotion/styled";

const StyledWrapper = styled.div`
  flex-grow: 1;
  .posts {
    .article-ads {
      margin-bottom: 1.5rem;
    }
  }
`;

export default function Articles({
  title,
  totalCount,
  numPages,
  currentPage,
  posts,
}) {
  return (
    <StyledWrapper className="article-section">
      <div className="article-section__main">
        <InfoHeader title={title} count={totalCount} />
        <Pagination currentPage={currentPage} numPages={numPages} />
        <div className="posts">
          {posts.map((post, i) => {
            return <Post key={post.id} post={post} />;
          })}
        </div>
        <Pagination currentPage={currentPage} numPages={numPages} />
      </div>
    </StyledWrapper>
  );
}
